.loading {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: #222;
    z-index: 99;
    justify-content: center;
    align-items: center;
}

.loading--image {
    width:68px;
}

.loading--image img {
    width: 100%;
    object-fit: contain;
}