.contact-button {
    height: 100%;
    width: 100%;
    border: solid 1px #fff;
    border-radius: 9999px;
    box-shadow: 3px 3px 10px  rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 3.6%;
}

.contact-button:hover{
    cursor: pointer;
}

.contact-button--image {
    height: 100%;
    width: 17.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-button--image img {
    width: 100%;
    object-fit: contain;
    scale: 0.9;
    transition: all 0.2s ease;
}

.contact-button:hover .contact-button--image img {
    scale: 1;
}

.contact-button--text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-button--text .text {
    text-align: center;
    text-indent: 0px;
    font-size: 18px;
}

.text.contact-button--sub {
    text-indent: 0px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: -1px;
}

.contact-button--centralizer {
    content: '.';
    visibility: hidden;
    height: 14px;
}