.langitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
}

.langitem img {
    height: 20px;
}

.langitem .text {
    text-indent: 0px;
}

.langitem--level {
    display: inline-block;
    /* background-color: #fff; */
    border: solid 2px #fff;
    border-radius: 5px;
    height: 8px;
    width: 20px;
    /* margin: 0px 5px; */
}

.langitem--level--filled {
    background-color: #fff;
}