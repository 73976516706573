.lang-button {
    border: none;
    height: 100%;
    border: solid 1px #fff;
    border-radius: 50%;
    scale: 0.95;
    transition: all 0.1s ease;
}

.lang-button:hover {
    cursor: pointer;
    scale: 1;
}

.lang-button img {
    height: 100%;
}