.skill-item--group {
    position: relative;
}

.skill-item {
    height: 100px;
    width: 100px;
    position: relative;
    border: solid 2px #fff;
    border-radius: 25px;
    transition: 0.2s;
    scale: 0.95;
    margin: 10px
}

.skill-item:hover{
    cursor: pointer;
    background-color: #fff;
    scale: 1;
}


.skill-item img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding: 10px;
    scale: 0.95;
    transition: 0.2s;
}

.skill-item:hover img{
    scale: 1;
}

.skill-item--popup--background {
    display: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 90;
    backdrop-filter: blur(3px);
}

.skill-item--popup--background.skill-item--popup--open {
    display: block;
}

.skill-item--popup {
    visibility: hidden;
    opacity: 0;
    height: 0px;
    width: 0px;
    max-width: 350px;
    max-height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border: solid 2px #fff;
    border-radius: 30px;
    background-color: #222;
    box-shadow: 5px 5px 10px rgba(10, 10, 10, 0.8);
    transition: all 0.5s ease;
}

.skill-item--popup.skill-item--popup--open {
    visibility: visible;
    opacity: 1;
    height: 300px;
    width: 300px;
}

.skill-item--popup--close {
    height: 5%;
    width: 5%;
    position: absolute;
    top: 5%;
    right: 5%;
    scale: 0.9;
}

.skill-item--popup--close:hover {
    cursor: pointer;
    scale: 1;
}

.skill-item--popup--close img {
    height: 100%;
    width: 100%;
}

.skill-item--popup--content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.skill-item--popup--image {
    height: 25%;
    width: 100%;
}

.skill-item--popup--image img {
    padding: 3%;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.skill-item--popup--body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-item--popup--title .text {
    font-family:  'Roboto-Regular', sans-serif;
    text-indent: 0px;
    font-size: 0px;
    transition: all 0.5s ease;
}

.skill-item--popup--title.skill-item--popup--open .text {
    font-size: 150%;
}

.skill-item--popup--text {
    flex-grow: 1;
    margin: 5% 0px;
    overflow: hidden;
}

.skill-item--popup--text .text {
    padding: 0px 10px;
    text-indent: 5%;
    font-size: 0px;
    transition: all 0.5s ease;
}

.skill-item--popup--text.skill-item--popup--open .text {
    font-size: 110%;
}

.skill-item--popup--links {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.skill-item--popup--links a {
    border: solid 2px #fff;
    border-radius: 15px;
    text-indent: 0px;
    font-size: 0px;
    text-decoration: none;
    padding: 0px;
    transition: all 0.5s ease;
}

.skill-item--popup--links.skill-item--popup--open a {
    font-size: 110%;
    padding: 5px;
}

.skill-item--popup--links a:hover {
    background-color: #fff;
    color: #222;
}