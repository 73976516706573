.arrow {
    width: 25px;
    height: 30px;
    position: absolute;
    scale: 0.9;
    opacity: 0.7;
    left: calc(50% - 12.5px);
    transition: 0.2s;
    animation: moving-arrow 1s ease infinite;
}

.arrow:hover,
.arrow:active {
    cursor: pointer;
    scale: 1;
    opacity: 1;
}

@keyframes moving-arrow {
    0%    {bottom: 10px;}
    50%   {bottom: 7px;}
    100%  {bottom: 10px;}
}

@media (hover : none) {
    .arrow {
        transition: 0s;
    }
}