.education {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: #222;
}

.education--wrapper {
    margin: 50px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}

.education--content {
    display: flex;
    width: 100%;
    height: 100%;
}

.education--image {
    position: relative;
    height: 100%;
    width: 40%;
}

.education--image img {
    /* padding: 50px; */
    padding-left: 0px;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.education--text {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.education--text > * {
    margin: 40px 0px;
}

.education--text *:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.education--responsive--image {
    display: none;
    height: 50%;
    /* flex-grow: 1; */
    width: 100%;
    margin-top: 20px;
}


.education--responsive--image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width : 1190px) {
    .education--image {
        display: none;
    }
    .education--content {
        justify-content: center;
    }
    .education--text {
        align-items: center;
    }
    .education--responsive--image {
        display: block;
    }
}

@media screen and (max-width : 815px) {
    .education--responsive--image {
        height: 30%;
        margin-top: 20px;
    }
    .education--text {
        align-items: center;
    }
}