.computer {
    width: 100%;
    height: 100%;
    position: relative;
}

.computer img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
}

.pc {
    z-index: 1;
    animation: computer-animation 3s ease-in-out infinite;
}

@keyframes computer-animation {
    0%      {top: 0px;}
    50%     {top: -1%;}
    100%    {top: 0px;}
}