.experience {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: #222;
}

.experience--wrapper {
    margin: 50px 0px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience--title {
    margin-bottom: 20px;
}