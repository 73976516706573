.experience-slider {
    position: relative;
    max-width: 930px;
    max-height: 356px;
    height: 50vh;
    width: 100%;
    /* background-color: red; */
    overflow: hidden;
    margin: auto;
}

.experience-slider--items {
    height: 100%;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    transition: left 0.5s ease;
    padding: 7px 0px;
}

.experience-slider--left,
.experience-slider--right {
    display: none;
    height: 80%;
    width: 25px;
    z-index: 10;
    background-color: rgba(3,3,3,0.5);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: background-color 0.2s ease;
}

.experience-slider--visible {
    display: block;
}

.experience-slider--left:hover,
.experience-slider--right:hover {
    cursor: pointer;
    background-color: rgba(3,3,3,0.8)
}

.experience-slider--left img,
.experience-slider--right img {
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.experience-slider--left {
    left: 0px;
    border-radius: 0px 10px 10px 0px;
}

.experience-slider--left img {
    transform: rotate(90deg);
}

.experience-slider--right {
    right: 0px;
    border-radius: 10px 0px 0px 10px;
}

.experience-slider--right img {
    transform: rotate(-90deg);
}