.degree {
    width: fit-content;
}

.degree .text,
.degree--responsive .text {
    text-indent: 0px;
    text-align: right;
    width:max-content;
}

.degree > .text,
.degree--responsive > .text {
    text-decoration:  none;
    position: relative;
}

.degree > .text:hover,
.degree--responsive > .text:hover {
    cursor: pointer;
}

.degree > .text:after,
.degree--responsive > .text:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #7b1cc8;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.degree > .text:hover:after,
.degree--responsive > .text:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.degree span,
.degree--responsive--university {
    font-family: 'Roboto-Normal', sans-serif;
}

.degree--info {
    display: flex;
    justify-content: space-between;
}

.degree--info .text {
    font-size: 14px;
}

.degree--info img {
    height: 10px;
}

/* Responsive Degree */
.degree--responsive {
    display: none;
    text-align: left;
    text-align: center;
}

.degree--responsive .degree--info {
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width : 1190px) {
    .degree,
    .degree--responsive {
        margin: 20px 0px;
    }
}

@media screen and (max-width : 815px) {
    .degree {
        display: none;
    }

    .degree--responsive {
        display: block;
    }
}

@media screen and (max-width : 650px) {
    .degree--responsive > .text {
        font-size: 5vw;
    }
}

/* @media screen and (max-width : 815px) {
    .degree > .text {
        font-size: 25px;
    }

    .degree--info .text {
        font-size: 12px;
    }

    .degree--info img {
        height: 8px;
    }
}

@media screen and (max-width : 695px) {
    .degree > .text {
        font-size: 20px;
    }
} */

/* @media screen and (max-width : 570px) {
    .degree > .text {
        font-size: 3.0vw;
    }

    .degree--info .text {
        font-size: 2.5vw;
    }

    .degree--info img {
        height: 2vw;
    }
} */