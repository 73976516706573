* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url('assets/fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Lobster';
    src: url('assets/fonts/Lobster/Lobster-Regular.ttf');
}

.fp-watermark {
    display: none;
}

.app {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Lobster', cursive, serif;
    font-weight: normal;
    font-size: 55px;
    color: #fff;
    text-align: center;
}

.text {
    font-family: 'Roboto-Thin', sans-serif;
    font-weight: normal;
    font-size: 30px;
    color: #fff;
    text-align: justify;
    text-indent: 50px;
}