.home {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    position: relative;
    justify-content: flex-end;
    background-color: #000000;
}

.home--left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    margin: auto;
    margin-left: 50px;
    padding: 20px 0px;
    max-width: 50%;
}

.home--left::after {
    content: '.';
    height: 25px;
    visibility: hidden;
}

.home--lang {
    width: 110px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home--text {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home--text p {
    font-family: 'Roboto-Thin', sans-serif;
    font-size: 40px;
    font-weight: normal;
}

.home--text h1 {
    font-family: 'Lobster', cursive, serif;
    font-size: 53px;
    font-weight: normal;
}

.home--text h1 span {
    font-family: 'Roboto-Thin', sans-serif;
    font-size: 26px;
    font-weight: normal;
}

.home--right {
    height: 100%;
}

.home--profile-responsive {
    display: none;
}

@media screen and (max-width : 900px) {
    .home--right {
        display: none;
    }
    .home--left {
        max-width: none;
        width: 100%;
        margin: 0px;
        padding: 0px;
        justify-content: flex-start;
    }
    .home--left > *:not(.home--profile-responsive) {
        margin-left: 50px;
    }
    .home--profile-responsive {
        display: block;
        height: 50%;
        background: linear-gradient(to top, rgb(0, 0, 0, 1) 1%,9%,rgba(0,0,0,0) 90%),
            url('../../assets/imgs/profile_2.png');
        background-position: center;
        background-size: cover;
    }
    .home--lang {
        margin: 10px 0px
    }
    .home--text {
        flex-grow: 1;
        margin-bottom: 70px;
        min-height: 170px;
    }
}

@media screen and (max-width : 700px) {
    .home--text p {
        font-size: 5.7vw;
    }
    .home--text h1 {
        font-size: 7.57vw;
    }
    .home--text h1 span {
        font-size: 4vw;
    }
    .home--left::after {
        display: none;
    }
}