.lang-selector {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 40px;
}

.lang-selector--top {
    background-color: #333;
    display: flex;
    border-radius: 5px;
    height: 20px;
    width: 100%;
}

.lang-selector--top:hover {
    cursor: pointer;
}

.lang-selector--selected {
    height: 100%;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang-selector--arrow {
    width: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang-selector--arrow img {
    height: 100%;
    scale: 0.3;
    object-fit: contain;
    transition: 0.2s;
}

.lang-selector--dropdown {
    position: absolute;
    top: 20px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #333;
    height: 40px;
    width: 25px;
    border-radius: 0px 0px 5px 5px;
    transition: 0.2s;
}

.lang-selector--option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 20px;
}

.lang-selector--option:hover {
    cursor: pointer;
    background-color: #222;
}

.lang-selector--invert {
    transform: scale(1, -1);
}

.lang-selector--open {
    display: flex;
}

.lang-selector--open ~ .lang-selector--top {
    border-bottom-left-radius: 0px;
}