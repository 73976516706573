.about {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: #222;
}

.about--wrapper {
    margin: 50px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}

.about--content {
    display: flex;
    height: 100%;
    width: 100%;
}

.about--text {
    max-height: 700px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 30px; */
    overflow-y: hidden;
}

.about--image {
    width: 40%;
    padding: 1%;
}

.about--responsive--image {
    display: none;
    height: 30%;
    width: 100%;
    padding: 1%;
    flex-grow: 1;
}

@media screen and (max-width : 970px) {
    .about--wrapper {
        /* margin: 10px; */
        height: calc(100% - 20px);
    }
    .about--image {
        display: none;
    }
    .about--responsive--image {
        display: block;
    }
    .about--content {
        height: 60%;
    }
    .about--text {
        justify-content: flex-start;
        width: 100%;
    }
    .about--text .text {
        font-size: 27px;
    }
}

@media screen and (max-width : 740px) {
    .about--text .text {
        font-size: 20px;
    }
}