.contact {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: #222;
    overflow: hidden;
}

.contact--wrapper {
    margin: 50px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact--content {
    flex-grow: 1;
    width: calc(100vw - 100px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.contact--content > div {
    width: 230px;
    height: calc(230px/4);
}

.contact--centralizer {
    content: '.';
    visibility: hidden;
    height: 69px;
    width: 100%;
}

@media screen and (max-width : 890px) and (min-height : 450px) {
    .contact--content {
        flex-direction: column;
        gap: 30px;
    }
}