.experience-item {
    max-height: 342px;
    height: 100%;
    box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin: 0px 5px;
}

.experience-item--image {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 10%;
    background-color: #fff;
    height: 90%;
    width: 100%;
}

.experience-item--image img {
    position: absolute;
    width: 100%;
    object-fit: contain;
    padding: 10px;
    transition: 1s;
    z-index: 1;
}

.experience-item--content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 80%;
    height: 342px;
    width: 100%;
    border-radius: 25px;
    background-color: #130028;
    box-shadow: 0px -1px 10px 5px rgba(0, 0, 0, 0.3);
    transition: 1s;
    z-index: 5;
}

.experience-item--content:hover {
    cursor: pointer;
    top: 40%;
}

.experience-item--content:hover~.experience-item--image img {
    filter: blur(5px);
}

.experience-item--title {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 23px;
    text-indent: 0px;
    margin: 10px 0px 5px 0px;
}

.experience-item--period {
    font-size: 12px;
    text-indent: 0px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 10px;
}

.experience-item--text {
    font-size: 15px;
    padding: 0px 15px;
    text-indent: 20px;
    text-align: justify;
}

.experience-item--popup--background {
    display: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 90;
    backdrop-filter: blur(3px);
}

.experience-item--popup--background.experience-item--popup--open {
    display: block;
}

.experience-item--popup {
    visibility: hidden;
    opacity: 0;
    height: 0px;
    width: 0px;
    max-width: 350px;
    max-height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border: solid 2px #fff;
    border-radius: 30px;
    background-color: #130028;
    box-shadow: 5px 5px 10px rgba(10, 10, 10, 0.8);
    transition: all 0.5s ease;
}

.experience-item--popup.experience-item--popup--open {
    visibility: visible;
    opacity: 1;
    height: 300px;
    width: 300px;
}

.experience-item--popup--close {
    height: 5%;
    width: 5%;
    position: absolute;
    top: 5%;
    right: 5%;
    scale: 0.9;
}

.experience-item--popup--close:hover {
    cursor: pointer;
    scale: 1;
}

.experience-item--popup--close img {
    height: 100%;
    width: 100%;
}

.experience-item--popup--content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.experience-item--popup--body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience-item--popup--title {
    margin-top: 5%;
}

.experience-item--popup--title .text {
    text-indent: 0px;
    font-size: 0px;
    text-align: center;
    transition: all 0.5s ease;
}

.experience-item--popup--title h1 {
    font-family:  'Roboto-Regular', sans-serif;
}

.experience-item--popup--title.experience-item--popup--open h1 {
    font-size: 150%;
}

.experience-item--popup--title.experience-item--popup--open p {
    font-size: 90%;
    color: rgba(255, 255, 255, 0.8);
}

.experience-item--popup--text {
    flex-grow: 1;
    margin: 5% 0px;
    overflow: hidden;
}

.experience-item--popup--text .text {
    padding: 0px 10px;
    text-indent: 5%;
    font-size: 0px;
    transition: all 0.5s ease;
}

.experience-item--popup--text.experience-item--popup--open .text {
    font-size: 110%;
}

.experience-item--popup--links {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.experience-item--popup--links a {
    font-family: 'Roboto-Thin', sans-serif;
    font-weight: normal;
    text-align: justify;
    color: #fff;
    border: solid 2px #fff;
    border-radius: 15px;
    text-indent: 0px;
    font-size: 0px;
    text-decoration: none;
    padding: 0px;
    transition: all 0.5s ease;
}


.experience-item--popup--links.experience-item--popup--open a {
    font-size: 110%;
    padding: 5px;
}

.experience-item--popup--links a:hover {
    background-color: #fff;
    color: #222;
}

@media screen and (max-height : 415px) {
    .experience-item--title {
        font-size: 15px;
    }
    .experience-item--period {
        font-size: 10px;
    }
    .experience-item--text {
        font-size: 12px;
    }
}

@media screen and (max-height : 340px) {
    .experience-item--title {
        font-size: 12px;
    }
    .experience-item--period {
        font-size: 8px;
    }
    .experience-item--text {
        font-size: 10px;
    }
}