.skills--slider {
    position:relative;
    max-width: 720px;
    overflow: hidden;
}

.skills--slider--border {
    border-top: solid 2px rgba(17, 17, 17, 0.3);
    border-bottom: solid 2px rgba(17, 17, 17, 0.3);
}

.skills--items {
    position: relative;
    top: 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: 0.5s ease;
}

.skills--slider--up,
.skills--slider--down {
    visibility: hidden;
    position: absolute;
    left: calc(50% - 75px);
    height: 25px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(3,3,3,0.5);
    transition: background-color 0.2s ease;
}

.skills--slider--visible {
    visibility: visible;
}

.skills--slider--up:hover,
.skills--slider--down:hover {
    cursor: pointer;
    background-color: rgba(3,3,3,0.8)
}

.skills--slider--up:hover img,
.skills--slider--down:hover img{
    scale: 1;
}

.skills--slider--up img,
.skills--slider--down img {
    height: 100%;
    padding: 5px;
    scale: 0.9;
    object-fit: contain;
}

.skills--slider--up {
    border-radius: 0px 10px;
}

.skills--slider--up img {
    transform: scale(1, -1);
}

.skills--slider--down {
    bottom: 0px;
    border-radius: 10px 0px;
}
