.footer {
    height: 80px;
    width: 100vw;
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-radius: 15px 15px 0px 0px;
    background-color: #111;
    box-shadow: 3px -3px 10px  rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
}

.footer a {
    text-decoration: none;
    font-family: 'Roboto-Regular';
}

.footer .text {
    text-indent: 0px;
    font-size: 12px;
}

.footer--copyright,
.footer--sources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.footer--logo {
    height: 100%;
}

.footer--logo img {
    scale: 0.8;
    height: 100%;
    object-fit: contain;
}

.footer--sources {
    align-items: flex-end;
}

@media screen and (max-width : 400px) {
    .footer .text {
        font-size: 8px;
    }

    .footer--logo img {
        scale: 0.6;
    }
}