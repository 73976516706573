.menu {
    height: 50px;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    z-index: 10;
    border-radius: 0px 0px 15px 15px;
    background-color: #1b1b1b;
    box-shadow: 3px 3px 10px  rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}

.menu.menu--hide {
    top: -50px
}

.menu--content {
    height: 100%;
    width: 640px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu--content .text {
    height: 100%;
    text-indent: 0px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: 0.2s;
}

.menu--content .text:hover {
    cursor: pointer;
    background-color: #222;
}

.menu--lang {
    position: absolute;
    top: 15px;
    right: 40px;
    height: 20px;
    width: 50px;
}

/* Responsive Menu */

.menu--responsive {
    display: none;
    height: 10px;
    width: 10px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 10;
    transition: right 0.5s ease;
}

.menu--responsive.menu--hide {
    right: -200px;
}

.menu--responsive--image {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 35px;
    width: 35px;
    scale: 0.9;
    z-index: 1;
    transition : scale 0.2s linear, right 1s ease;
}

.menu--responsive--image:hover {
    cursor: pointer;
    scale: 1;
}

.menu--responsive--image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.menu--responsive--image.menu--open img {
    padding: 5px;
    content : url('../../assets/imgs/close.png')
}

.menu--responsive--body {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: -200px;
    height: 100vh;
    width: 200px;
    border-radius: 10px 0px 0px 10px;
    transition: right 0.5s ease;
    background-color: #1b1b1b;
    box-shadow: -3px 3px 10px  rgba(0, 0, 0, 0.2);
}

.menu--responsive--body.menu--open {
    right: 0px;
}

.menu--responsive--lang {
    position: absolute;
    top: 17.5px;
    left: calc(50% - 20px);
}

.menu--responsive--content {
    height: 50%;
    width: 100%;
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu--responsive--content .text {
    height: 100%;
    text-indent: 0px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: 0.2s;
}

.menu--responsive--content .text:hover {
    cursor: pointer;
    background-color: #222;
}

@media screen and (max-width : 750px) {
    .menu--lang {
        right: 5px;
    }
}

@media screen and (max-width : 815px) {
    .menu {
        display: none;
    }
    .menu--responsive {
        display: block;
    }
}