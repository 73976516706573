.skills {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #222;
}

.skills--wrapper {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
}

.skills--lang {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
}

@media screen and (max-width : 930px) {
    .skills--lang {
        height: 150px;
        gap: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .skills--lang * {
        margin: 2px 0px;
    }
}